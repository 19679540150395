var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "stat-date"
  }, [_c("el-date-picker", {
    attrs: {
      type: "datetimerange",
      "picker-options": _vm.pickerOptions,
      clearable: false
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1), _c("div", {
    staticClass: "stat-summary"
  }, [_c("div", {
    staticClass: "summary-item"
  }, [_c("label", [_vm._v(_vm._s(_vm.$t("public-dns.stat.total-queries")))]), _c("div", {
    staticClass: "summary-item-digit"
  }, [_vm._v(_vm._s(_vm.allrequest))])]), _c("div", {
    staticClass: "summary-item"
  }, [_c("label", [_vm._v(_vm._s(_vm.$t("public-dns.stat.blocked-queries")))]), _c("div", {
    staticClass: "summary-item-digit"
  }, [_vm._v(_vm._s(_vm.blockrequest))])]), _c("div", {
    staticClass: "summary-item"
  }, [_c("label", [_vm._v(_vm._s(_vm.$t("public-dns.stat.percent-block")))]), _c("div", {
    staticClass: "summary-item-digit"
  }, [_vm._v(_vm._s(_vm.blockRate))])])]), _c("div", {
    staticClass: "stat-chart"
  }, [_c("div", [_c("QChart", {
    attrs: {
      id: "main",
      chartData: _vm.qData,
      title: _vm.qTitle
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };