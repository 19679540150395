import service from '@/utils/request';

// @Tags Bill
// @Summary 获取Bill列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "获取Bill列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bill/getbilldatalist [get]

export var getBilldatalist = function getBilldatalist(params) {
  return service({
    url: "/bill/getbilldatalist",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};

// @Tags BillingData
// @Summary 获取BillingData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param start query int true "开始时间戳"
// @Param end query int true "结束时间戳"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bill/getgeodatalist [get]

export var getGEOdata = function getGEOdata(params) {
  return service({
    url: "/bill/getgeodatalist",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};

// @Tags BillingData
// @Summary 获取Zone/Domain的Top数据
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param start query int true "开始时间戳"
// @Param end query int true "结束时间戳"
// @Param zone query string false "zone名称"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bill/gettopdatalist [get]

export var getTopData = function getTopData(params) {
  return service({
    url: "/bill/gettopdatalist",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};

// @Tags BillingData
// @Summary 获取PublicDns的请求明细数据
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param start query int true "开始时间戳"
// @Param end query int true "结束时间戳"
// @Param authcode query string false "配置UUID"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bill/getpubdnsdatadetail [get]

export var getpubdnsdatadetail = function getpubdnsdatadetail(params) {
  return service({
    url: "/bill/getpubdnsdatadetail",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};

// @Tags BillingData
// @Summary 获取PublicDns的请求数据
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param start query int true "开始时间戳"
// @Param end query int true "结束时间戳"
// @Param authcode query string false "配置UUID"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bill/getpubdnsdata [get]

export var getpubdnsdata = function getpubdnsdata(params) {
  return service({
    url: "/bill/getpubdnsdata",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};